import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoadingComponent from '../../components/common/LoadingComponent';
import closeIcon from '../../images/lab-test/close.svg';
import covidHeader from '../../images/covid/header.svg';
import teleIcon from '../../images/covid/tele.svg';
import diagnosticIcon from '../../images/covid/diagnostic.svg';
import hotlineIcon from '../../images/covid/helpline.svg';
import prescriptionIcon from '../../images/covid/prescription.svg';
import { initializeAmplitudeClient } from '../../services/analytics';
import maleIcon from '../../images/buying-journey/male.png';
import femaleIcon from '../../images/buying-journey/female.png';
import Modal from '../../containers/common/Modal';
import { FlyInTransition } from '../../components/transitions/transitions';
import crossIcon from '../../images/common/cross.svg';
import infoIcon from '../../images/buying-journey/info.svg';
import membersIcon from '../../images/buying-journey/members.svg';
import removeActive from '../../images/covid/remove-active.svg';
import removeInactive from '../../images/covid/remove-inactive.svg';
import addActive from '../../images/covid/add-active.svg';
import addInactive from '../../images/covid/add-inactive.svg';
import config from '../../config';
import specialistIcon from '../../images/covid/specialist.svg';
import { logEvent } from '../../utils/logEvent';

class AddDependent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      total: 2499,
      perMemberPrice: 2499,
      showModal: true,
      noOfDependents: 0,
    };
  }

  hideModal = () => {
    this.setState({ showModal: false });
    setTimeout(this.props.hideModal, 200);
  };

  handleOutsideClick = (e) => {
    if (this.modalNode && this.modalNode.contains(e.target)) {
      return;
    }
    this.hideModal();
  };

  addRelative = () => {
    const { name, editing, age, gender, phone } = this.state;
    const { editingMember } = this.props;
    this.props.addRelative(name, phone, age, gender, editingMember);
  };

  render() {
    const { editing, noOfDependents, perMemberPrice, total } = this.state;
    return (
      <Modal>
        <div className="modalContainer" onClick={this.handleOutsideClick}>
          <FlyInTransition
            in={this.state.showModal}
            mountOnEnter
            unmountOnExit
            appear
          >
            <div
              className="bg-textbox absolute bottom-0px w-full rounded-t-3xl max-h-full overflow-auto"
              ref={(node) => (this.modalNode = node)}
            >
              <div className="p-20 border-b border-txtsecondary-shades7 border-solid flex flex-row items-center justify-between">
                <div className="flex flex-row items-center">
                  <img src={membersIcon} alt="home" />
                  <span className="text-title-s ml-10px font-semibold text-txtlight">
                    Add Dependents
                  </span>
                </div>
                <img src={crossIcon} alt="cross" onClick={this.hideModal} />
              </div>
              <div className="p-20 max-h-full overflow-auto">
                <div
                  className="flex flex-row  justify-between items-center rounded-12 px-20px py-16px mb-32px"
                  style={{
                    boxShadow: ' 0px 2px 2px rgba(0, 0, 0, 0.05)',
                    border: '2px solid #e27396',
                  }}
                >
                  <div className="flex flex-row items-center">
                    <img
                      src={
                        this.props.user && this.props.user.userGender === 'Male'
                          ? maleIcon
                          : femaleIcon
                      }
                      width={'21px'}
                      alt="self"
                    />

                    <p className="ml-12px text-txtlight font-semibold text-body-l">
                      Self
                    </p>
                  </div>
                  <div className="flex flex-row items-center">
                    <strike className="text-body-l text-brochure font-semibold opacity-50">
                      ₹4999
                    </strike>
                    <p className="text-body-l text-black font-bold ml-8px">
                      ₹2499
                    </p>
                  </div>
                </div>
                <div>
                  <p className="text-txtlight font-semibold text-body-s">
                    ADD YOUR DEPENDENTS TOO
                  </p>
                  <div
                    className="mt-16px flex flex-row justify-between items-center rounded-12 px-20px py-16px"
                    style={
                      noOfDependents > 0
                        ? {
                            boxShadow: ' 0px 2px 2px rgba(0, 0, 0, 0.05)',
                            border: '2px solid #e27396',
                          }
                        : { border: '1px solid #EBEAF5' }
                    }
                  >
                    <div className="flex flex-col">
                      <p className="text-body-s font-semibold text-txtlight">
                        Dependents
                      </p>
                      <p className="font-medium text-chip text-secondary-shades6">
                        {perMemberPrice}/person
                      </p>
                    </div>
                    <div className="flex flex-row items-center">
                      <img
                        className="hover mr-8px"
                        alt="remove"
                        onClick={
                          noOfDependents > 0
                            ? () => {
                                this.setState({
                                  noOfDependents: noOfDependents - 1,
                                  total: total - perMemberPrice,
                                });
                              }
                            : () => {}
                        }
                        src={noOfDependents > 0 ? removeActive : removeInactive}
                      />
                      <div className="text-title-m w-20px text-center font-medium text-txtlight">
                        {noOfDependents ? noOfDependents : 0}
                      </div>
                      <img
                        alt="add"
                        className="hover ml-8px"
                        onClick={() => {
                          this.setState({
                            noOfDependents: noOfDependents + 1,
                            total: total + perMemberPrice,
                          });
                        }}
                        src={addActive}
                      />
                    </div>
                  </div>
                  <div
                    className="mt-40px mb-20px bg-primary pl-32px pr-32px rounded-12 hover pt-12px pb-12px text-button-s flex flex-row items-center justify-between font-semibold text-textbox"
                    style={{
                      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
                    }}
                    onClick={() => this.props.checkout(noOfDependents, total)}
                  >
                    <p>₹{total}</p>
                    <p>Pay & Proceed</p>
                  </div>
                </div>
              </div>
            </div>
          </FlyInTransition>
        </div>
      </Modal>
    );
  }
}

class CovidHome extends Component {
  static propTypes = {};
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      cost: 2499,
      price: 4999,
      showModal: false,
      fromCovidDashboard: false,
    };
    const { state } = props.location;
    console.log(state, props);
    if (state && state.fromCovidDashboard) {
      this.state.fromCovidDashboard = state.fromCovidDashboard;
    }
  }
  componentDidMount() {
    // GA Tracking
    window.gtag('event', 'screen_view', {
      screen_name: 'Covid Home Page',
    });
    this.amplitudeClient = initializeAmplitudeClient();
    this.amplitudeClient.init();
    logEvent('Covid Home Care Homepage');
  }

  closeView = () => {
    const { fromCovidDashboard } = this.state;
    if (fromCovidDashboard) {
      this.props.history.replace('/covid-packages', {
        disableBack: true,
      });
      return;
    }
    if (typeof window.Android !== 'undefined') {
      return window.Android.closeView();
    }
  };

  hideModal = () => {
    this.setState({
      showModal: false,
    });
  };

  continue = () => {
    if (!this.props.authToken) {
      this.props.history.replace('/login?rt=covid-home');
      return;
    }
    this.setState({
      showModal: true,
    });
  };

  redirectToPaymentPage = (noOfMember, total) => {
    const { authToken } = this.props;
    window.location.href = `${
      config.apiBaseUrl
    }/subscription/transact?subPlanTermId=4&amount=${total}&memberCount=${
      Number(noOfMember) + 1
    }&auth=${authToken.split(' ')[1]}`;
  };

  render() {
    const { history } = this.props;
    const { showModal } = this.state;
    return (
      <div className="overflow-auto w-full">
        <div className="mb-120px">
          <div
            className="header w-full pl-20px pt-48px flex flex-row justify-between items-start"
            style={{ background: '#E7FAFF', color: '#2D0000' }}
          >
            <div className="flex flex-col self-end mb-48px">
              <div className="mt-20px pr-20px">
                <p className="text-title-l font-bold mb-8px text-txtlight">
                  Are you COVID-19 Positive?
                </p>
                <p className="font-bold text-body-l">COVID-19 Home Care Plan</p>
                <p className="font-bold text-body-s mt-4px">
                  for you and your family
                </p>
              </div>
            </div>
            <div className="flex flex-col items-end">
              <img
                src={closeIcon}
                alt="close"
                className="hover mb-10px"
                onClick={() => {
                  this.closeView();
                }}
              />
              <img
                src={covidHeader}
                alt="covid-19"
                className="self-end"
                style={{ width: '170px' }}
              />
            </div>
          </div>
          <div className="body px-20px py-24px">
            <div
              className="flex flex-row items-center justify-between py-24px px-16px mb-24px rounded-12"
              style={{ background: 'rgba(30, 184, 101, 0.1)' }}
            >
              <p className="text-title-m text-black font-bold">15 days</p>
              <div className="flex fle-row items-center">
                <strike className="text-title-m text-brochure font-semibold opacity-50">
                  ₹4999
                </strike>
                <p className="text-title-m text-black font-bold ml-4px">
                  ₹2499
                </p>
              </div>
            </div>
            <div className="flex flex-row flex-wrap justify-between items-start">
              <div className="pb-12px mb-24px border-b border-txtsecondary-shades4">
                <p className="text-title-m font-bold text-txtlight">
                  Introducing an all new Home quarantine program
                </p>
              </div>
              <div className="flex flex-col">
                <div className="flex flex-row items-start mb-24px">
                  <img src={teleIcon} />
                  <p className="text-body-l font-regular text-txtlight ml-12px">
                    A COVID Specialist doctor connects 7 times in a period of 15
                    days
                  </p>
                </div>
                <div className="flex flex-row items-start mb-24px">
                  <img src={specialistIcon} />
                  <p className="text-body-l font-regular text-txtlight ml-12px">
                    Access to consultations with COVID Specialists,
                    Pulmonologists & Psychologists
                  </p>
                </div>
                <div className="flex flex-row items-start mb-24px">
                  <img src={diagnosticIcon} />
                  <p className="text-body-l font-regular text-txtlight ml-12px">
                    An RT PCR home collection is provided to every employee
                    signing up for the program
                  </p>
                </div>
                <div className="flex flex-row items-start mb-24px">
                  <img src={prescriptionIcon} />
                  <p className="text-body-l font-regular text-txtlight ml-12px">
                    Follow up with Care Specialists for regular monitoring of
                    Vitals & Symptoms
                  </p>
                </div>
                <div className="flex flex-row items-start mb-24px">
                  <img src={hotlineIcon} />
                  <p className="text-body-l font-regular text-txtlight ml-12px">
                    A dedicated 24x7 emergency helpline for urgent query
                    resolutions
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="footer absolute bottom-0 w-full px-24px py-16px">
            <div
              className="pl-20px pr-16px rounded-12 hover pt-12px pb-12px text-button-s font-semibold text-textbox text-center"
              style={{
                background: '#1EB865',
                backdropFilter: 'blur(4px)',
                minWidth: '100%',
                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
              }}
              onClick={() => {
                this.continue();
              }}
            >
              Subscribe
            </div>
          </div>
          {showModal ? (
            <AddDependent
              showModal={showModal}
              user={this.props.user}
              hideModal={this.hideModal}
              checkout={this.redirectToPaymentPage}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  insured: state.app.config.insured,
  user: state.user,
});
export default connect(mapStateToProps)(CovidHome);
