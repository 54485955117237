import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Tappable from 'react-tappable/lib/Tappable';
import { FaCircleNotch } from 'react-icons/fa';

import { BoldText, ButtonText } from './Text';

const StyledTappable = styled(Tappable)`
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 12px 28px;
  color: #ffffff !important;
  background: ${(props) =>
    props.disabled ? '#B2B2B2' : props.color ? props.color : '#e27396;'};
  &.visit-button-tappable-active {
    opacity: 0.2;

    &.visit-button-loading {
      opacity: 1;
    }

    &.visit-button-disabled {
      opacity: 1;
    }
  }
`;

const FitcoinStyledTappable = styled(StyledTappable)`
  background-image: linear-gradient(to right, #a37520, #ede67b);
`;

const SubscriptionStyledTappable = styled(StyledTappable)`
  background-image: linear-gradient(to left, #ffa14f, #db4c6d);
`;

const LoadingContainer = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: center;
`;

const LoadingIcon = styled(FaCircleNotch)`
  color: white;
  margin-right: 0.5rem;
`;

const LoadingText = styled(ButtonText)`
  color: white;
`;

const InvertedTappable = styled(StyledTappable)`
  background: white;
  color: ${(props) => props.color || '#8852CC'};
`;

const SubscriptionInvertedStyledTappable = styled(StyledTappable)`
  border-width: 1px 1px 1px 1px;
  border-style: solid;
  border-color: #ffa14f;
  // border-image: linear-gradient(to left, #FFA14F, #DB4C6D) 1 / 1px;
  background: white;
`;

export const LinkText = styled(BoldText)`
  color: #8852cc;
  font-size: ${() => `${1 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  cursor: pointer;
`;

const PrimaryButton = ({
  children,
  className,
  onTap,
  loading,
  disabled,
  color,
}) => {
  let klass = className;
  if (loading) {
    klass = `${className} visit-button-loading`;
  } else if (disabled) {
    klass = `${className} visit-button-disabled`;
  }
  return (
    <StyledTappable
      onTap={() => {
        !loading && !disabled && onTap && onTap();
      }}
      disabled={disabled}
      component="div"
      stopPropagation
      classBase="visit-button-tappable"
      className={klass}
      color={color}
    >
      {loading ? (
        <LoadingContainer>
          <LoadingIcon className="icon-spin" />
          <LoadingText>Loading...</LoadingText>
        </LoadingContainer>
      ) : (
        children
      )}
    </StyledTappable>
  );
};

PrimaryButton.propTypes = {
  children: PropTypes.element.isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onTap: PropTypes.func,
};

PrimaryButton.defaultProps = {
  loading: false,
  disabled: false,
  onTap: null,
};

const FitcoinButton = ({ children, className, onTap, loading, disabled }) => {
  let klass = className;
  if (loading) {
    klass = `${className} visit-button-loading`;
  } else if (disabled) {
    klass = `${className} visit-button-disabled`;
  }
  return (
    <FitcoinStyledTappable
      onTap={() => {
        !loading && !disabled && onTap && onTap();
      }}
      disabled={disabled}
      component="div"
      stopPropagation
      classBase="visit-button-tappable"
      className={klass}
    >
      {loading ? (
        <LoadingContainer>
          <LoadingIcon className="icon-spin" />
          <LoadingText>Loading...</LoadingText>
        </LoadingContainer>
      ) : (
        children
      )}
    </FitcoinStyledTappable>
  );
};

FitcoinButton.propTypes = {
  children: PropTypes.element.isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onTap: PropTypes.func,
};

FitcoinButton.defaultProps = {
  loading: false,
  disabled: false,
  onTap: null,
};

const SubscriptionButton = ({
  children,
  className,
  onTap,
  loading,
  disabled,
}) => {
  let klass = className;
  if (loading) {
    klass = 'visit-button-loading';
  } else if (disabled) {
    klass = 'visit-button-disabled';
  }
  return (
    <SubscriptionStyledTappable
      onTap={() => {
        !loading && !disabled && onTap && onTap();
      }}
      disabled={disabled}
      component="div"
      stopPropagation
      classBase="visit-button-tappable"
      className={klass}
    >
      {loading ? (
        <LoadingContainer>
          <LoadingIcon className="icon-spin" />
          <LoadingText>Loading...</LoadingText>
        </LoadingContainer>
      ) : (
        children
      )}
    </SubscriptionStyledTappable>
  );
};

SubscriptionButton.propTypes = {
  children: PropTypes.element.isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onTap: PropTypes.func,
};

SubscriptionButton.defaultProps = {
  loading: false,
  disabled: false,
  onTap: null,
};

const InvertedSubscriptionButton = ({
  children,
  className,
  onTap,
  loading,
  disabled,
}) => {
  let klass = className;
  if (loading) {
    klass = `${className} visit-button-loading`;
  } else if (disabled) {
    klass = `${className} visit-button-disabled`;
  }
  return (
    <SubscriptionInvertedStyledTappable
      onTap={() => {
        !loading && !disabled && onTap && onTap();
      }}
      disabled={disabled}
      component="div"
      stopPropagation
      classBase="visit-button-tappable"
      className={klass}
    >
      {loading ? (
        <LoadingContainer>
          <LoadingIcon className="icon-spin" />
          <LoadingText>Loading...</LoadingText>
        </LoadingContainer>
      ) : (
        children
      )}
    </SubscriptionInvertedStyledTappable>
  );
};

InvertedSubscriptionButton.propTypes = {
  children: PropTypes.element.isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onTap: PropTypes.func,
};

InvertedSubscriptionButton.defaultProps = {
  loading: false,
  disabled: false,
  onTap: null,
};

const InvertedButton = ({ children, onTap, color }) => (
  <InvertedTappable
    onTap={onTap}
    stopPropagation
    classBase="visit-button-tappable"
    color={color}
  >
    {children}
  </InvertedTappable>
);

InvertedButton.propTypes = {
  children: PropTypes.element.isRequired,
  color: PropTypes.string,
  onTap: PropTypes.func,
};

InvertedButton.defaultProps = {
  color: null,
  onTap: null,
};

export {
  PrimaryButton,
  FitcoinButton,
  InvertedButton,
  SubscriptionButton,
  InvertedSubscriptionButton,
};
