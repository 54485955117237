import React, { Component } from 'react'
import googleIcon from '../../images/buying-journey/google.png';
import appleIcon from '../../images/buying-journey/apple.png';
import { fontSize } from '../../utils';

export class BuyingJourneyCongratulations extends Component {
  closeView = () => {
    if (typeof window.Android !== 'undefined') {
        return window.Android.reloadApp();
    } else if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
          JSON.stringify({
              method: 'reload',
            })
        );
      }
    }
  render() {
    return (
      <div className="w-full overflow-auto flex flex-col items-center justify-center font-mont text-center journey-success">
        <p
          className="font-bold text-brochure mb-40px resp-28px"
          style={{
            borderBottom: ' 1px solid #e273961a',
          }}
        >
          Congratulations
        </p>
        <img
          src={
            'https://visit-public.s3.ap-south-1.amazonaws.com/assets/one-health/success.png'
          }
          style={{
            maxHeight: '130px',
          }}
          alt="success"
        />
        <p
          className="journey-activated font-bold mt-36px"
          style={{ color: '#3A2CA0' }}
        >
          Your plan has been activated
        </p>

        <button
          style={{ borderRadius: '15px' }}
          className="p-20"
          onClick={this.closeView}
        >
          <span
            style={{ color: '#493E98', fontWeight: 'bold', fontSize: '16px' }}
          >
            Continue
          </span>
        </button>

        {/* <p className="journey-startusing font-semibold margint-60px text-brochure">
              Start using your benefits on Visit App!
            </p>
            <p className="text-title-s font-semibold mt-22px text-brochure">
              Download Now
            </p>
            <img
              src={appleIcon}
              onClick={() => {
                window.location.href =
                  'https://itunes.apple.com/in/app/visit-free-chat-with-a-doctor/id1169969848?mt=8';
              }}
              className="mt-12px journey-icon"
              style={{ height: 'auto', maxWidth: '300px' }}
              alt="app"
            />
            <img
              src={googleIcon}
              onClick={() => {
                window.location.href =
                  'https://play.google.com/store/apps/details?id=com.getvisitapp.android';
              }}
              className="mt-32px journey-icon"
              style={{ height: 'auto', maxWidth: '300px' }}
              alt="app"
            /> */}
      </div>
    );
  }
}

export default BuyingJourneyCongratulations
