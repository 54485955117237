import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FaCheck } from 'react-icons/fa';

import { RegularText } from '../../common/Text';

const OuterContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  max-width: 80%;
  align-items: center;
  justify-content: flex-end;
  align-self: flex-end;
  margin-right: 20px;
`;

const BubbleContainer = styled.div`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  padding: 12px 16px;
  background: #e27396;
  border-radius: 12px 12px 0px 12px;
`;

const MessageText = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #ffffff;
  word-break: break-word;
`;

const TimestampContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  margin-left: 1rem;
`;

const TickIcon = styled(FaCheck)`
  color: #fff;
  opacity: 0.5;
  font-size: ${() => `${0.625 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  margin-right: 0.25rem;
`;

const TimestampText = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  margin-top: 8px;
  color: #75818f;
  margin-right: 20px;
`;

const UserMessage = ({ message, timestamp }) => (
  <div className="flex flex-col mt-4">
    <OuterContainer>
      <BubbleContainer>
        <MessageText>{message}</MessageText>
      </BubbleContainer>
    </OuterContainer>
    {timestamp && (
      <TimestampText>
        {new Date(timestamp).toLocaleTimeString('nu', {
          hour: 'numeric',
          minute: '2-digit',
        })}
      </TimestampText>
    )}
  </div>
);

UserMessage.propTypes = {
  message: PropTypes.string,
  timestamp: PropTypes.number,
};

export default UserMessage;
